import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

interface MigrationData {
  venue: string;
  name: string;
}

export default class MigrationApiService extends ApiService<any> {
  constructor() {
    super('migration');
  }

  public migrateDevToDev(data: MigrationData): AxiosPromise {
    return this.migrate(data, 'dev-to-dev');
  }

  public migrateDevToProd(data: MigrationData): AxiosPromise {
    return this.migrate(data, 'dev-to-prod');
  }

  public migrateProdToProd(data: MigrationData): AxiosPromise {
    return this.migrate(data, 'prod-to-prod');
  }

  protected migrate(data: MigrationData, type: 'dev-to-dev' | 'dev-to-prod' | 'prod-to-prod'): AxiosPromise {
    return axios.post(`${this.getBaseUrl()}/${type}`, { venue: data.venue, names: [data.name] });
  }
}
