
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import MigrationApiService from '@/api/http/MigrationApiService';
import MigrationForm from '@/components/venue/form/MigrationForm.vue';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import StackedForm from '@/mixins/StackedForm';

const venue = namespace('venue');

@Component({
  components: { MigrationForm, VWrapper },
})
export default class VenueMigration extends mixins(Notification, StackedForm) {
  public $refs!: {
    form: InstanceType<typeof MigrationForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  @venue.State('active') public venue!: Venue;

  @venue.Action('show') public show: any;
  @venue.Action('publish') public publish: any;

  get id() {
    return this.$route.params.id;
  }

  public mounted(): void {
    this.$startLoading('venue');
    this.show({ id: this.id }).finally(() => {
      this.$stopLoading('venue');
    });
  }

  public submit() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (this.isValid(res)) {
        const api: MigrationApiService = new MigrationApiService();
        this.$startLoading('migration');
        const data: { venue: string; name: string; type: string } = this.$refs.form.getData();
        if (data.type === 'dTd') {
          api.migrateDevToDev(data).finally(() => {
            this.$stopLoading('migration');
          });
        } else if (data.type === 'dTp') {
          api.migrateDevToProd(data).finally(() => {
            this.$stopLoading('migration');
          });
        } else if (data.type === 'pTp') {
          api.migrateProdToProd(data).finally(() => {
            this.$stopLoading('migration');
          });
        }

        this.$router.push({ name: 'venue.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }
}
