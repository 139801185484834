
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { FormItem } from '@/interfaces/components/FormItem';
import { Permission } from '@/enums/Permission';
import { SelectItem } from '@/interfaces/components/SelectItem';

@Component({
  components: { VFormBuilder },
})
export default class MigrationForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Text, label: 'venue.form.name', rules: 'required' },
      { name: 'type', type: InputType.Select, label: 'venue.form.migrationType', items: this.types },
    ];
  }

  get types() {
    if (process.env.VUE_APP_API_URL?.includes('dev-api')) {
      const items: SelectItem[] = [];
      if (this.$can(Permission.VENUE_MIGRATE_DEV_TO_DEV)) {
        items.push({ text: 'Dev-To-Dev', value: 'dTd' });
      }
      if (this.$can(Permission.VENUE_MIGRATE_DEV_TO_PROD)) {
        items.push({ text: 'Dev-To-Prod', value: 'dTp' });
      }
      return items;
    }
    if (!this.$can(Permission.VENUE_MIGRATE_PROD_TO_PROD)) {
      return [];
    }
    return [{ text: 'Prod-To-Prod', value: 'pTp' }];
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
